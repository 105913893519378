// @ts-strict-ignore
import { FC, Fragment, useState } from 'react'
import { WidthProps } from 'styled-system'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { dataLayerSelectItem } from '@lib/data-layer-social-ads/select-item'
import { imgix } from '@lib/imigx'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Box, Image, Text, theme } from '@gassan-ui'
import AllOrPriceTitleVariant from './AllOrPriceTitleVariant'
import Header from './Header'
import LifestyleHover from './LifestyleHover'
import * as S from './elements'

type Props = WidthProps & {
  images?: string[]
  brand?: string
  href?: string
  id?: string
  title?: string
  subtitle?: string
  price?: number
  discountPrice?: number
  label?: string
  isOnWishlist?: boolean
  onClickWishlist?: () => void
  isLoading?: boolean
  promotionLabel?: string
  hasBlendMode?: boolean
  description?: string
  articleType?: string
  isEmarsysItem?: boolean
  hidePrice?: boolean
}

export const ProductCard: FC<Props> = ({
  images,
  brand,
  href = '/p/[slug]',
  id,
  title,
  subtitle,
  price,
  discountPrice,
  isOnWishlist,
  onClickWishlist,
  isLoading,
  isEmarsysItem = false,
  label,
  width,
  promotionLabel,
  hasBlendMode,
  description,
  articleType,
  hidePrice = false,
}) => {
  const [hasMouseEntered, setHasMouseEntered] = useState(false)
  const alt = `${title} ${subtitle ? subtitle : brand ? brand : ''}`
  const lifestyleImage =
    images?.length > 1 && images.find((image) => image.toLowerCase().includes('_ls'))
  const handleMouseEnter = () => {
    if (typeof window.ontouchstart === 'undefined' && images?.length > 1) {
      setHasMouseEntered(true)
    }
  }

  const handleMouseLeave = () => setHasMouseEntered(false)
  const inner = (
    <Fragment>
      <Header
        isOnWishlist={isOnWishlist}
        onClickWishlist={onClickWishlist}
        isLoading={isLoading}
        label={label}
      />
      <S.BackgroundContainer>
        <S.ImagesContainer suppressHydrationWarning>
          {!isLoading && (
            <Image
              placeholderSrc={imgix(images[0], { width: 50 })}
              sources={[
                imgix(images[0], { width: 350 }),
                imgix(images[0], { width: 350 }),
                imgix(images[0], { width: 450 }),
              ]}
              alt={alt}
              className="absolute top-0 aspect-square"
              loading="lazy"
            />
          )}
        </S.ImagesContainer>
        {promotionLabel && (
          <Box
            position="absolute"
            bottom={{ _: '.75rem', medium: '1.5rem' }}
            right={{ _: '.5rem', medium: '1.5rem' }}
            pt={{ _: '.1rem', medium: '.25rem' }}
            pb={{ _: '.2rem', medium: '.25rem' }}
            px={{ _: '.5rem', medium: '.75rem' }}
            bg={theme.colors.bigStone}
            width="auto"
            textAlign="right"
          >
            <Text as="span" color="white" variant="small" fontWeight="bold" lineHeight="1">
              {promotionLabel}
            </Text>
          </Box>
        )}
      </S.BackgroundContainer>
      <AllOrPriceTitleVariant
        title={title}
        subtitle={subtitle}
        price={price}
        discountPrice={discountPrice}
        isLoading={isLoading}
        hidePrice={hidePrice}
        showLightTextOnHover={!!lifestyleImage}
      />
      {hasMouseEntered && lifestyleImage && <LifestyleHover src={lifestyleImage} alt={alt} />}
    </Fragment>
  )

  if (isLoading) {
    return (
      <S.LoadingWrapper
        data-scarabitem={isEmarsysItem ? id : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={width}
      >
        {inner}
      </S.LoadingWrapper>
    )
  }

  return (
    <LocalizedLink href={href}>
      <S.Anchor
        data-test-id={!isLoading && 'product-card'}
        data-scarabitem={isEmarsysItem ? id : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={width}
        hasBlendMode={hasBlendMode}
        href={href}
        onClick={() => {
          genericPushToTrackingLayer('Ecommerce', 'productClick')

          dataLayerSelectItem({
            item_id: id,
            item_name: description,
            currency: 'EUR',
            index: 0,
            item_brand: brand,
            item_category: 'Apparel & Accessories',
            item_category2: 'Jewelry',
            item_category3:
              articleType && ['Horloge', 'Watch'].includes(articleType) ? 'Watches' : undefined,
            item_list_id: 'related_products',
            item_list_name: 'Related Products',
            price: Math.round((discountPrice / 121 || price / 121) * 100) / 100,
            quantity: 1,
          })
        }}
      >
        {inner}
      </S.Anchor>
    </LocalizedLink>
  )
}
