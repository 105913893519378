import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import * as styles from './USPs.styles'

const iconKeys: ['free-delivery', 'personal-advice', 'free-returns', 'always-insured'] = [
  'free-delivery',
  'personal-advice',
  'free-returns',
  'always-insured',
]

const icons = {
  'free-delivery': [
    '/images/appointment-block/free_delivery_mobile.svg',
    '/images/appointment-block/free_delivery_desktop.svg',
  ],
  'personal-advice': [
    '/images/appointment-block/personal_advice_mobile.svg',
    '/images/appointment-block/personal_advice_desktop.svg',
  ],
  'free-returns': [
    '/images/appointment-block/free_returns_mobile.svg',
    '/images/appointment-block/free_returns_desktop.svg',
  ],
  'always-insured': [
    '/images/appointment-block/always_insured_mobile.svg',
    '/images/appointment-block/always_insured_desktop.svg',
  ],
}

const USPs: FC = () => {
  const { t } = useTranslation('global')
  return (
    <div className={styles.parent}>
      {iconKeys.map((icon, index) => (
        <div className={styles.icon} key={index}>
          <div className={styles.mobileIcon}>
            <img src={icons[icon][0]} alt={icon} />
          </div>
          <div className={styles.desktopIcon}>
            <img src={icons[icon][1]} alt={icon} />
          </div>
          <LinariaHeading
            variant="h5"
            as="span"
            mb="0"
            data-heading
            dangerouslySetInnerHTML={{
              __html: t(`${icon}`),
            }}
          ></LinariaHeading>
        </div>
      ))}
    </div>
  )
}

export default USPs
