import { FC } from 'react'
import styled from 'styled-components'
import { Box, WishListButton, breakpoints } from '@gassan-ui'
import { Text } from './elements'

const Container = styled(Box)`
  top: 0;
  width: 100%;
  padding: 0.25rem 0.75rem;
  height: 2rem;
  z-index: 4;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (min-width: ${breakpoints.small}) {
    align-items: flex-end;
    height: 4.25rem;
    padding: 0 1.5rem;
  }
`

type Props = {
  label?: string
  isOnWishlist: boolean
  onClickWishlist: () => void
  isLoading: boolean
}

const Header: FC<Props> = ({ label, isLoading, isOnWishlist, onClickWishlist }) => {
  return (
    <Container>
      {!isLoading && (
        <>
          <Text fontWeight={600} mb={[0, 2]} mt={[1, 0]}>
            {label}
          </Text>
          <WishListButton isOnWishlist={isOnWishlist} onClick={onClickWishlist}></WishListButton>
        </>
      )}
    </Container>
  )
}

export default Header
